import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Notification from "../components/Notification"

const Methoden = () => (
  <Layout>
    <SEO title="TDCS Rosenheim" />
    <Notification />

    <h1>Methoden</h1>

    <h2>Transkranielle Gleichstromstimulation</h2>
    <h4>Durchführung</h4>
    <p>
      Die angestrebte Wirkung der tDCS wird bestimmt durch Größe und
      Positionierung der beiden Flächenelektroden. Die Behandlungen dauern 10
      oder 20 Minuten. Was bei medikamentöser Therapie Dosierung und
      Einnahmehäufigkeit darstellen, sind bei Anwendung der tDCS die
      Stimulationsparameter. In der Vergangenheit wurden verschiedene Parameter
      auf ihre Wirksamkeit hin untersucht, wobei insbesondere die Faktoren
      Frequenz, Intensität, Gesamtzahl der Stimuli, Ort der Applikation eine
      Rolle spielen. Empirisch begründete Parameter ergeben sich aus den
      verschiedenen Studien. Insgesamt kristallisieren sich nach Nitsche et al.
      (2003) als determinierende Faktoren heraus:
    </p>
    <ol>
      <li>Stromdichte (Stimulationsstärke (A)/Elektrodengröße (cm2))</li>
      <li>Gesamtladung (Stromstärke/Elektrodengröße x Stimulationsdauer)</li>
      <li>Ladung pro Phase (Stromstärke x Dauer eines Einzelimpulses)</li>
      <li>
        Ladungsdichte (Stromstärke/Elektrodengröße x Dauer eines Einzelimpulses)
      </li>
    </ol>

    <h4>Eingesetzt</h4>
    <p>
      Eingesetzt wird tDCS bei chronischen Schmerzen, z.B. beim
      Fibromyalgiesyndrom, und bei Depressionen ein. Außerdem kommt tDCS
      regelmäßig bei der Migräneprophylaxe zur Anwendung, wenn es gilt, jenseits
      der medikamentösen Therapie, die zuweilen als sehr belastend,
      Nebenwirkungs-behaftet und nur dürftig wirksam empfunden wird, eine
      nebenwirkungsfreie Hilfe zu bekommen.  In der Neurologie findet tDCS
      Anwendung bei der Behandlung der Epilepsie, bei  Morbus Parkinson, bei
      Multipler Sklerose, Spastik und Rehabilitation nach Schlaganfall.
    </p>

    <h4>Sicherheit und Nebenwirkungen</h4>
    <p>
      Sicherheit und Nebenwirkungen Übereinkunft besteht, dass die tDCS bei
      Beachtung der Richtlinien hinsichtlich Kontraindikationen und
      Stimulationsparameter eine gut verträgliche und nebenwirkungsarme Methode
      ist (Nitsche et al. 2003, Fregni et al. 2006, Iyer et al. 2005): es können
      keine Krampfanfälle ausgelöst werden.Die tDCS wird ambulant und ohne
      Begleitmedikation durchgeführt, da die Behandlung gut vertragen wird und
      keine Beeinträchtigungen, unangenehme Nebenwirkungen oder Risiken zu
      befürchten sind. Mehrere Studien zur Sicherheit der tDCS und zur
      Evaluation der Nebenwirkungen haben zu klaren Empfehlungen hinsichtlich
      der sicheren Anwendung geführt. Nur selten werden leichte Müdigkeit oder
      Übelkeit geklagt, die aber von alleine nach kurzer Zeit abklingen. Es
      können elektrisch induzierte lokale Muskelkontraktionen während der
      Stimulation auftreten, verbunden mit einer Reizung der Kopfhaut, was als
      Kribbeln und Ziehen beschrieben wurde (Fregni et al. 2006). Gewebeschäden
      wurden auch nach hohen Stimulusintensitäten und - frequenzen nie
      beobachtet.
    </p>

    <h4>Kosten</h4>
    <p>
      Kosten tDCS-Neuromodulation ist als individuelle Gesundheitsleistung
      (IGEL) bezahlbar und wird gemäß §12 des Sozialgesetzbuches nicht von den
      gesetzlichen Krankenkassen übernommen,. Eine Behandlungssitzung kostet bei
      Ansetzen der GOÄ-Analogziffer 832a (3,5-facher Satz) 32 €. Wir gehen von
      10 bis 15 Sitzungen aus. Einige private Krankenversicherungen erstatten
      die tDCS-Behandlung.
    </p>
    <p>
      Fehlgeleitete neuroplastische Veränderungen wirken jedoch nachteilig.
      Hirnforscher nehmen an, dass sie die Ursache für viele chronische
      Schmerzsyndrome, Migräne, Tinnitus oder neurologische Erkrankungen sind.
      Sie suchen daher nach Lösungen, wie sie die Neuroplastizität beeinflussen
      und therapeutisch nutzen können. Eine der Lösungen ist tDCS. Ein sehr
      schwacher, kontinuierlicher Gleichstrom stimuliert das Gehirn für mehrere
      Minuten. Die Behandlung ist schmerzlos und die Patienten sind bei
      Bewusstsein. In klinischen Studien untersuchen Forscher die Wirksamkeit
      bei Schmerzen, Epilepsie, Morbus Parkinson, Multipler Sklerose, Spastik
      und nach einem Schlaganfall. Dieses sanfte Kribbeln verändert die
      Gehirnzellen. Die Umstrukturierungen in den Gehirnzellen können
      beispielsweise dazu führen, dass die Zelle die unzähligen und ständig auf
      sie einströmenden Signale nicht mehr richtig verarbeiten kann. So ist bei
      Migränepatienten die Erregbarkeit der Nervenzellen in der für das Sehen
      zuständigen Hirnregion erhöht. „Mit der Gleichstromstimulation wollen wir
      die Übererregbarkeit drosseln und so Migräneattacken verhindern“, erklärt
      Paulus den Wirkmechanismus. In weiteren klinischen Studien mit Schmerz-
      und Migränepatienten untersuchen die Göttinger Wissenschaftler, wie sie
      die Gleichstromtherapie in ein akzeptiertes und sicheres therapeutisches
      Verfahren umsetzen können. Paulus: „Dann stünde zahlreichen Betroffenen
      mit chronischen Schmerzen und Migräne eine wirksame Therapiealternative
      zur Verfügung.“
    </p>

    <h2>Repetitive transkranielle Magnetstimulation (rTMS)</h2>
    <p>
      Kurze und hochfrequente Stimulation mit einem starken Magnetfeld löst im
      Gehirn einen elektrischen Strom aus. Mindestens 1.000 Impulse von weniger
      als einer Millisekunde Dauer werden täglich abgegeben. Die Wirkung der
      schmerzlosen Behandlung auf schwerste Depressionen und Epilepsien sowie
      chronische Schmerzen wird derzeit in Studien untersucht. Im Gegensatz zur
      Gleichstrombehandlung kann die rTMS Krampfanfälle auslösen.
    </p>

    <h2>Craniale Elektrostimulation</h2>
    <p>
      Die craniale Elektrostimulation (cranial electro stimulation, CES) ist in
      der Medizin ein elektromedizinisches Behandlungsverfahren, bei dem über
      zwei Elektroden über die Kopfhaut oder über die Ohrmuscheln ein geringer
      elektrischer Wechselstrom zugeführt wird. Die Stromstärken liegen dabei
      unter einem Milli ampere und werden von den Patienten nicht gespürt. Die
      eingesetzten Spannungen liegen bei wenigen Volt und der Frequenzbereich
      liegt üblicherweise zwischen 0,5 und 100 Hertz . Die ersten Versuche auf
      diesem Gebiet wurden Rouxeau im Jahre 1902 von den Franzosen Leduc
      durchgeführt. Die eigentliche CES entstand jedoch erst in den fünfziger
      Jahren in Russland , und zu Beginn der sechziger Jahre in den USA und ist
      mit der Entwicklung der EKT ( Elektrokrampftherapie ) verbunden, die
      vorher in den 1930er Jahren entstand. Zurzeit wird die CES-Therapie bei
      folgenden Erkrankungen eingesetzt: zur Raucherentwöhnung, Alkoholismus,
      Alkoholentzug, Opiat- Entzug, Depression, chronische Schmerzzustände,
      Schlafstörungen, chronische Angstzustände . Die CES wird in der
      wissenschaftlichen Medizin eingesetzt. Versprechend scheint die Wirkung
      bei chronischen Schmerzen. Wirkmechanismen: eine Wirkung der elektrischen
      Ströme auf das aufsteigende retikuläre Aktivierungssystem , des limbischen
      System und des Hypothalamus wird vermutet. In mehreren Studien konnte eine
      Beeinflussung des Elektroenzephalogramms (EEG) nachgewiesen werden.
      Neurotransmitterkonzentrationen scheinen durch die CES beeinflussbar zu
      sein. Nach 20 Minuten CES-Behandlung wurden für Beta-Endorphin im Plasma
      um 98 %, in der Gehirn-Rückenmarks-Flüssigkeit um 219 % und für Serotonin
      in der Gehirn-Rückenmarks-Flüssigkeit um 200 % erhöhte Werte gemessen.
    </p>
  </Layout>
)

export default Methoden
